<template>
  <loading-spinner class="position-relative bg-light py-5" v-if="isLoading">
    Loading dependant details
  </loading-spinner>
  <assessment
    v-else-if="
      !isLoading &&
      currentView &&
      !['details', 'assessments', 'certificates'].includes(
        currentView
      )
    "
    :assessmentId="currentView"
    :dependantId="currentDependant._id"
    @close="closeAssessment"
  />
  <div
    v-else-if="
      !isLoading &&
      currentView &&
      ['details', 'assessments', 'certificates'].includes(
        currentView
      )
    "
  >
    <div
      class="p-3 bg-blue-dark text-light rounded shadow-sm border d-flex align-items-center"
    >
      <button
        class="btn btn-sm py-0 me-3 lh-1"
        @click="$router.go(-1)"
        v-if="!$store.state.user.isStudent"
      >
        <i class="fas fa-arrow-left text-light" />
      </button>
      <p class="mb-0 text-truncate fw-medium lh-1" v-if="currentDependant">
        {{ currentDependant.name }}
      </p>
      <span class="text-muted fst-italic" v-else>Tiada murid dipilih</span>
    </div>
    <div
      class="py-3 text-nowrap overflow-auto no-scrollbar"
      v-if="currentDependant"
    >
      <button
        class="btn ms-2 border ps-2 py-1 pe-2 rounded shadow-sm overflow-hidden text-nowrap clickable"
        :class="
          $route.query && $route.query.view == 'assessments' || currentView == 'details'
            ? 'text-primary bg-white'
            : 'text-muted'
        "
        @click="changeView('assessments')"
      >
        <small class="fw-medium">Penilaian</small>
      </button>
      <button
        class="btn ms-2 border ps-2 py-1 pe-2 rounded shadow-sm overflow-hidden text-nowrap clickable"
        :class="
          $route.query && $route.query.view == 'certificates'
            ? 'text-primary bg-white'
            : 'text-muted'
        "
        @click="changeView('certificates')"
      >
        <small class="fw-medium">Sijil</small>
      </button>
    </div>
    <template v-if="currentView == 'assessments' || currentView == 'details'">
      <div
        class="text-center bg-white border py-3 shadow-sm fst-italic text-muted small rounded"
        v-if="classAssessments.length == 0"
      >
        Tiada penilaian tersedia
      </div>
      <div class="row g-3 mx-0" v-else>
        <div
          class="col-12 bg-white border rounded shadow-sm p-3 d-flex justify-content-between align-items-center flex-column flex-lg-row"
          v-for="assessment in classAssessments"
          :key="assessment._id"
        >
          <div class="mb-3 mb-lg-0 text-center text-lg-start">
            <div
              class="d-flex flex-column flex-lg-row justify-content-center justify-content-lg-start align-items-center mb-2"
            >
              <span
                class="badge me-lg-2 mb-2 mb-lg-0"
                :class="`bg-${Helper.getLevelColor(
                  classLevel(assessment.class)
                )}`"
                v-if="assessment.class && classLevel(assessment.class)"
              >
                <small>{{ classLevel(assessment.class) }}</small>
              </span>
              <small class="fw-medium">
                {{ Helper.removeBreakLine(className(assessment.class)) }}
              </small>
            </div>
            <p class="mb-0">
              {{ assessment.title }}
            </p>
          </div>
          <div class="d-flex align-items-center ps-3">
            <button
              @click="takeAssessment(assessment._id, true)"
              class="btn btn-sm btn-secondary text-light lh-sm me-2 text-nowrap"
              v-if="isSchoolAdmin == true && assessmentMarks(assessment._id) !== null || isOfficer == 'Officer' && assessmentMarks(assessment._id) !== null"
            >
              Papar Keputusan
              <i class="fas fa-eye ms-2" />
            </button>
            <button
              class="btn btn-sm btn-danger text-light lh-sm me-2 text-nowrap" style="cursor: not-allowed;"
              v-else-if="isSchoolAdmin == true && assessmentMarks(assessment._id) == null || isOfficer == 'Officer' && assessmentMarks(assessment._id) == null"
            >
              Belum Jawab
            </button>
            <template v-else>
              <button
                class="btn btn-sm text-light lh-sm me-2 text-nowrap"
                :class="
                  assessmentMarks(assessment._id) !== null &&
                  assessmentMarks(assessment._id) < 80
                    ? 'bg-purple-dark'
                    : 'bg-blue-dark'
                "
                v-if="assessmentMarks(assessment._id) < 80"
                @click="takeAssessment(assessment._id)"
              >
                <span
                  v-if="
                    assessmentMarks(assessment._id) !== null &&
                    assessmentMarks(assessment._id) < 80
                  "
                >
                  Jawab Semula
                </span>
                <span v-else>Jawab</span>
                penilaian
                <i class="fas fa-caret-right ms-2" />
              </button>
              <button
                @click="takeAssessment(assessment._id, true)"
                class="btn btn-sm btn-secondary text-light lh-sm me-2 text-nowrap"
                v-else
              >
                Papar Keputusan
                <i class="fas fa-eye ms-2" />
              </button>
            </template>
            <span
              class="badge border fw-normal fs-6"
              :class="
                assessmentMarks(assessment._id) !== null
                  ? assessmentMarks(assessment._id) >= 80
                    ? 'bg-success'
                    : 'bg-warning'
                  : 'bg-light text-muted'
              "
            >
              {{ assessmentMarks(assessment._id) || 0 }}%
            </span>
          </div>
        </div>
      </div>
    </template>
    <template v-if="currentView == 'certificates'">
      <div class="table-responsive">
        <table class="table lh-sm table-admin table-hover w-100">
          <thead>
            <tr>
              <th style="width: 1%" class="pe-0">#</th>
              <th>Sijil</th>
              <th style="width: 1%" class="text-center">Tarikh</th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="(cert, index) in certificates"
              :key="cert._id"
              @click="currentMedia = cert.card"
            >
              <td style="width: 1%" class="pe-0">{{ index + 1 }}</td>
              <td class="text-nowrap text-truncate lh-sm">
                {{ cert.title }}
              </td>
              <td
                class="text-nowrap text-truncate text-center"
                style="width: 1%"
              >
                {{ Helper.formatDate(cert.date) }}
              </td>
            </tr>
            <tr v-if="certificates.length < 1">
              <td colspan="3" class="text-center fst-italic text-muted">
                Tiada sijil tersedia
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </template>
    <media-viewer
      v-if="currentMedia"
      :url="this.Helper.formatMediaUrl(currentMedia)"
      :mime-type="this.Helper.getMediaMimeType(currentMedia)"
      @close="currentMedia = null"
    />
  </div>
</template>

<script>
import Swal from "sweetalert2";
import MediaViewer from "@/components/Modals/MediaViewer";
import Assessment from "@/components/Assessment";
import LoadingSpinner from "@/components/LoadingSpinner";

export default {
  props: ["currentDependant"],
  components: {
    MediaViewer,
    Assessment,
    LoadingSpinner,
  },
  data() {
    return {
      isLoading: false,
      currentView: "details",
      currentMedia: null,
      classes: [],
      featuredVideos: [],
      assessmentsData: [],
      certificates: [],
      isSchoolAdmin: false,
      isOfficer: null,
    };
  },
  watch: {
    currentDependant: {
      async handler() {
        if (this.currentDependant) {
          if (
            this.currentDependant.classes &&
            this.currentDependant.classes.length > 0
          ) {
            await this.getClasses();
          }

          if (
            this.currentDependant.dependant_certificates &&
            this.currentDependant.dependant_certificates.length > 0
          ) {
            await this.getCertificates();
          }
          await this.getAssessments();
          await this.getFeaturedVideos();
          this.isSchoolAdmin = this.$store.state.user.schoolAdmin
          this.isOfficer = this.$store.state.user.role.name
        }
      },
      deep: true,
      immediate: true,
    },
  },
  computed: {
    classAssessments() {
      const assessments = this.assessmentsData

      return assessments.sort((a, b) => {
        return new Date(b.createdAt) - new Date(a.createdAt);
      });
    },
    className() {
      return (classId) => {
        const cls = this.classes.find((cls) => cls._id == classId);

        if (cls) {
          return cls.name;
        } else {
          return null;
        }
      };
    },
    classLevel() {
      return (classId) => {
        const cls = this.classes.find((cls) => cls._id == classId);

        if (cls) {
          return cls.level;
        } else {
          return null;
        }
      };
    },
    classResources() {
      return (classId) => {
        const cls = this.classes.find((cls) => cls._id == classId);

        if (cls && cls.resources) {
          const resources = cls.resources.filter(
            (ca) => ca.status == "Published"
          );

          let groupedResources = [];

          resources.forEach((resource, index) => {
            const x = groupedResources.find((gr) => gr.title == resource.title);

            if (x) {
              if (resource.youtube_link) {
                x.youtube_link = resource.youtube_link;
              } else if (resource.file) {
                x.file = resource.file;
              }
            } else {
              groupedResources.push({
                _id: index,
                title: resource.title,
                youtube_link: resource.youtube_link
                  ? resource.youtube_link
                  : null,
                file: resource.file ? resource.file : null,
              });
            }
          });

          return groupedResources.sort((a, b) => {
            const aTitleSplit = a.title.split(" ");
            const bTitleSplit = b.title.split(" ");

            const lastNumberA = parseInt(aTitleSplit[aTitleSplit.length - 1]);
            const lastNumberB = parseInt(bTitleSplit[bTitleSplit.length - 1]);

            return lastNumberA - lastNumberB;
          });
        } else {
          return [];
        }
      };
    },
    assessmentMarks() {
      return (assessmentId) => {
        const assessment = this.currentDependant.dependant_assessments
          ? this.currentDependant.dependant_assessments.find(
              (da) => da.assessment == assessmentId
            )
          : null;

        return assessment ? assessment.marks || 0 : null;
      };
    },
  },
  methods: {
    viewResource(resource, type) {
      if (!resource) return;

      if (type == "youtube" && resource.youtube_link) {
        // window.open(resource.youtube_link, "_blank");

        this.currentMedia = {
          url: "https://www.youtube.com/embed/".concat(
            resource.youtube_link.replace("https://youtu.be/", "")
          ),
          mime: "youtube",
        };
      } else if (type == "file" && resource.file) {
        this.currentMedia = resource.file;
      }
    },
    changeView(view) {
      if (view !== "details" && this.$route.query.view !== view) {
        this.$router.replace({ query: { view: view } });
      } else if (view == "details" && this.$route.query.view) {
        this.$router.replace({ query: {} });
      }
    },
    closeAssessment(e) {
      window.scrollTo(0, 0);

      if (e && e.data) {
        let updatedCurrentDependant = JSON.parse(
          JSON.stringify(this.currentDependant)
        );

        let assessmentIndex =
          updatedCurrentDependant.dependant_assessments.findIndex(
            (da) => da.assessment == e.data.assessment
          );

        if (assessmentIndex > -1) {
          updatedCurrentDependant.dependant_assessments[assessmentIndex] =
            e.data;
        } else {
          updatedCurrentDependant.dependant_assessments.push(e.data);
        }

        this.$emit("update", updatedCurrentDependant);
      }

      this.currentView = "assessments";
    },
    takeAssessment(assessmentId, readOnly) {
      const assessment = this.classAssessments.find(
        (ca) => ca._id == assessmentId
      );

      if (!assessmentId || !assessment) {
        return;
      }

      const dependantAssessment = this.currentDependant.dependant_assessments
        ? this.currentDependant.dependant_assessments.find(
            (da) => da.assessment == assessmentId
          )
        : null;

      if (readOnly) {
        this.currentView = JSON.stringify({
          id: assessmentId,
          dependantAssessment: dependantAssessment
            ? dependantAssessment._id
            : null,
          assessmentResult: dependantAssessment
            ? dependantAssessment.answers
            : null,
          assessmentMarks: dependantAssessment
            ? dependantAssessment.marks
            : null,
        });
      } else {
        Swal.fire({
          title: `<h5 class="mb-0">${
            dependantAssessment ? "Retake" : "Take"
          } this assessment?</h5>`,
          html: `
          <p class="small bg-light rounded border shadow-sm p-3 text-dark">${assessment.title}</p>
          <p class="mb-0"></p>
        `,
          icon: "question",
          showCancelButton: true,
          reverseButtons: true,
          confirmButtonText: "Yes",
          cancelButtonText: "No",
        }).then((result) => {
          if (result.isConfirmed) {
            this.currentView = JSON.stringify({
              id: assessmentId,
              dependantAssessment: dependantAssessment
                ? dependantAssessment._id
                : null,
            });
          }
        });
      }
    },
    async getClasses() {
      this.isLoading = true;

      return new Promise(async (resolve) => {
        try {
          const { data } = await this.API.get(
            `classes?_limit=-1`
          );

          this.classes = data;
        } catch (error) {
          console.error(error);
        } finally {
          this.isLoading = false;

          resolve();
        }
      });
    },
    async getAssessments() {
      this.isLoading = true;

      return new Promise(async (resolve) => {
        try {
          const { data } = await this.API.get(
            `assessments?_limit=-1`
          );

          this.assessmentsData = data;
        } catch (error) {
          console.error(error);
        } finally {
          this.isLoading = false;

          resolve();
        }
      });
    },
    async getFeaturedVideos() {
      this.isLoading = true;

      return new Promise(async (resolve) => {
        try {
          const { data } = await this.API.get(
            `featured-videos?_limit=-1`
          );

          this.featuredVideos = data;
        } catch (error) {
          console.error(error);
        } finally {
          this.isLoading = false;

          resolve();
        }
      });
    },
    async getCertificates() {
      this.isLoading = true;

      return new Promise(async (resolve) => {
        try {
          let url = `dependant-certificates?_limit=-1&dependant=${this.currentDependant._id}&_sort=date:DESC`;

          const { data } = await this.API.get(url);

          this.certificates = data;
        } catch (error) {
          console.error(error);
        } finally {
          this.isLoading = false;

          resolve();
        }
      });
    },
  },
  mounted() {
    if (this.$route.query && this.$route.query.view) {
      this.currentView = this.$route.query.view;
    }
  },
};
</script>