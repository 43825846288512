var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.isLoading)?_c('loading-spinner',{staticClass:"position-relative bg-light py-5"},[_vm._v(" Loading dependant details ")]):(
    !_vm.isLoading &&
    _vm.currentView &&
    !['details', 'assessments', 'certificates'].includes(
      _vm.currentView
    )
  )?_c('assessment',{attrs:{"assessmentId":_vm.currentView,"dependantId":_vm.currentDependant._id},on:{"close":_vm.closeAssessment}}):(
    !_vm.isLoading &&
    _vm.currentView &&
    ['details', 'assessments', 'certificates'].includes(
      _vm.currentView
    )
  )?_c('div',[_c('div',{staticClass:"p-3 bg-blue-dark text-light rounded shadow-sm border d-flex align-items-center"},[(!_vm.$store.state.user.isStudent)?_c('button',{staticClass:"btn btn-sm py-0 me-3 lh-1",on:{"click":function($event){return _vm.$router.go(-1)}}},[_c('i',{staticClass:"fas fa-arrow-left text-light"})]):_vm._e(),(_vm.currentDependant)?_c('p',{staticClass:"mb-0 text-truncate fw-medium lh-1"},[_vm._v(" "+_vm._s(_vm.currentDependant.name)+" ")]):_c('span',{staticClass:"text-muted fst-italic"},[_vm._v("Tiada murid dipilih")])]),(_vm.currentDependant)?_c('div',{staticClass:"py-3 text-nowrap overflow-auto no-scrollbar"},[_c('button',{staticClass:"btn ms-2 border ps-2 py-1 pe-2 rounded shadow-sm overflow-hidden text-nowrap clickable",class:_vm.$route.query && _vm.$route.query.view == 'assessments' || _vm.currentView == 'details'
          ? 'text-primary bg-white'
          : 'text-muted',on:{"click":function($event){return _vm.changeView('assessments')}}},[_c('small',{staticClass:"fw-medium"},[_vm._v("Penilaian")])]),_c('button',{staticClass:"btn ms-2 border ps-2 py-1 pe-2 rounded shadow-sm overflow-hidden text-nowrap clickable",class:_vm.$route.query && _vm.$route.query.view == 'certificates'
          ? 'text-primary bg-white'
          : 'text-muted',on:{"click":function($event){return _vm.changeView('certificates')}}},[_c('small',{staticClass:"fw-medium"},[_vm._v("Sijil")])])]):_vm._e(),(_vm.currentView == 'assessments' || _vm.currentView == 'details')?[(_vm.classAssessments.length == 0)?_c('div',{staticClass:"text-center bg-white border py-3 shadow-sm fst-italic text-muted small rounded"},[_vm._v(" Tiada penilaian tersedia ")]):_c('div',{staticClass:"row g-3 mx-0"},_vm._l((_vm.classAssessments),function(assessment){return _c('div',{key:assessment._id,staticClass:"col-12 bg-white border rounded shadow-sm p-3 d-flex justify-content-between align-items-center flex-column flex-lg-row"},[_c('div',{staticClass:"mb-3 mb-lg-0 text-center text-lg-start"},[_c('div',{staticClass:"d-flex flex-column flex-lg-row justify-content-center justify-content-lg-start align-items-center mb-2"},[(assessment.class && _vm.classLevel(assessment.class))?_c('span',{staticClass:"badge me-lg-2 mb-2 mb-lg-0",class:`bg-${_vm.Helper.getLevelColor(
                _vm.classLevel(assessment.class)
              )}`},[_c('small',[_vm._v(_vm._s(_vm.classLevel(assessment.class)))])]):_vm._e(),_c('small',{staticClass:"fw-medium"},[_vm._v(" "+_vm._s(_vm.Helper.removeBreakLine(_vm.className(assessment.class)))+" ")])]),_c('p',{staticClass:"mb-0"},[_vm._v(" "+_vm._s(assessment.title)+" ")])]),_c('div',{staticClass:"d-flex align-items-center ps-3"},[(_vm.isSchoolAdmin == true && _vm.assessmentMarks(assessment._id) !== null || _vm.isOfficer == 'Officer' && _vm.assessmentMarks(assessment._id) !== null)?_c('button',{staticClass:"btn btn-sm btn-secondary text-light lh-sm me-2 text-nowrap",on:{"click":function($event){return _vm.takeAssessment(assessment._id, true)}}},[_vm._v(" Papar Keputusan "),_c('i',{staticClass:"fas fa-eye ms-2"})]):(_vm.isSchoolAdmin == true && _vm.assessmentMarks(assessment._id) == null || _vm.isOfficer == 'Officer' && _vm.assessmentMarks(assessment._id) == null)?_c('button',{staticClass:"btn btn-sm btn-danger text-light lh-sm me-2 text-nowrap",staticStyle:{"cursor":"not-allowed"}},[_vm._v(" Belum Jawab ")]):[(_vm.assessmentMarks(assessment._id) < 80)?_c('button',{staticClass:"btn btn-sm text-light lh-sm me-2 text-nowrap",class:_vm.assessmentMarks(assessment._id) !== null &&
                _vm.assessmentMarks(assessment._id) < 80
                  ? 'bg-purple-dark'
                  : 'bg-blue-dark',on:{"click":function($event){return _vm.takeAssessment(assessment._id)}}},[(
                  _vm.assessmentMarks(assessment._id) !== null &&
                  _vm.assessmentMarks(assessment._id) < 80
                )?_c('span',[_vm._v(" Jawab Semula ")]):_c('span',[_vm._v("Jawab")]),_vm._v(" penilaian "),_c('i',{staticClass:"fas fa-caret-right ms-2"})]):_c('button',{staticClass:"btn btn-sm btn-secondary text-light lh-sm me-2 text-nowrap",on:{"click":function($event){return _vm.takeAssessment(assessment._id, true)}}},[_vm._v(" Papar Keputusan "),_c('i',{staticClass:"fas fa-eye ms-2"})])],_c('span',{staticClass:"badge border fw-normal fs-6",class:_vm.assessmentMarks(assessment._id) !== null
                ? _vm.assessmentMarks(assessment._id) >= 80
                  ? 'bg-success'
                  : 'bg-warning'
                : 'bg-light text-muted'},[_vm._v(" "+_vm._s(_vm.assessmentMarks(assessment._id) || 0)+"% ")])],2)])}),0)]:_vm._e(),(_vm.currentView == 'certificates')?[_c('div',{staticClass:"table-responsive"},[_c('table',{staticClass:"table lh-sm table-admin table-hover w-100"},[_c('thead',[_c('tr',[_c('th',{staticClass:"pe-0",staticStyle:{"width":"1%"}},[_vm._v("#")]),_c('th',[_vm._v("Sijil")]),_c('th',{staticClass:"text-center",staticStyle:{"width":"1%"}},[_vm._v("Tarikh")])])]),_c('tbody',[_vm._l((_vm.certificates),function(cert,index){return _c('tr',{key:cert._id,on:{"click":function($event){_vm.currentMedia = cert.card}}},[_c('td',{staticClass:"pe-0",staticStyle:{"width":"1%"}},[_vm._v(_vm._s(index + 1))]),_c('td',{staticClass:"text-nowrap text-truncate lh-sm"},[_vm._v(" "+_vm._s(cert.title)+" ")]),_c('td',{staticClass:"text-nowrap text-truncate text-center",staticStyle:{"width":"1%"}},[_vm._v(" "+_vm._s(_vm.Helper.formatDate(cert.date))+" ")])])}),(_vm.certificates.length < 1)?_c('tr',[_c('td',{staticClass:"text-center fst-italic text-muted",attrs:{"colspan":"3"}},[_vm._v(" Tiada sijil tersedia ")])]):_vm._e()],2)])])]:_vm._e(),(_vm.currentMedia)?_c('media-viewer',{attrs:{"url":this.Helper.formatMediaUrl(_vm.currentMedia),"mime-type":this.Helper.getMediaMimeType(_vm.currentMedia)},on:{"close":function($event){_vm.currentMedia = null}}}):_vm._e()],2):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }